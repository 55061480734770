import type { FC } from "react";
import { useEffect, useState } from "react";

import { SearchOutlined } from "@ant-design/icons";
import { Col, Form, Input, List, Row, Select, Spin } from "antd";

import type {
  SkyBoxCollection as ISkyBoxCollection,
  SkyBoxTag,
} from "@omi-lab/atlas-typescript";
import { SkyBoxOrderBy } from "@omi-lab/atlas-typescript";

import { useClientsStore } from "../../../store/clients";
import { SkyBoxList } from "../components/SkyBoxList";

function getCollectionLeaves(
  collection: ISkyBoxCollection,
): ISkyBoxCollection[] {
  function traverse(
    acc: ISkyBoxCollection[],
    node: ISkyBoxCollection,
  ): ISkyBoxCollection[] {
    if (node.collections?.length) return node.collections.reduce(traverse, acc);
    acc.push(node);
    return acc;
  }

  return traverse([], collection);
}

export const SkyBoxes: FC = () => {
  const [collections, setCollections] = useState<ISkyBoxCollection[]>([]);
  const [tags, setTags] = useState<SkyBoxTag[]>([]);

  const [filter, setFilter] = useState("");
  const [orderBy, setOrderBy] = useState<SkyBoxOrderBy>();
  const [collectionFilters, setCollectionFilters] = useState<string[]>([]);

  const [skyBoxCollectionsClient, skyBoxTagsClient] = useClientsStore(
    (state) => [state.skyBoxCollectionsClient, state.skyBoxTagsClient],
  );

  useEffect(() => {
    const getCollections = async () =>
      skyBoxCollectionsClient.listSkyBoxCollections();

    getCollections()
      .then((response) => response.data)
      .then((collections) =>
        collections.reduce(
          (acc, next) => [...acc, ...getCollectionLeaves(next)],
          [] as ISkyBoxCollection[],
        ),
      )
      .then((collections) => setCollections(collections));
  }, [skyBoxCollectionsClient, setCollections]);

  useEffect(() => {
    const getTags = async () => skyBoxTagsClient.listSkyBoxTags();

    getTags()
      .then((response) => response.data)
      .then((tags) => setTags(tags));
  }, [skyBoxTagsClient, setTags]);

  return (
    <Col style={{ padding: "15px" }}>
      {collections ? (
        <List grid={{ gutter: 16 }}>
          <List.Item>
            <Row gutter={16} justify="space-between">
              <Col span={12}>
                <Input
                  placeholder="Search for a sky box"
                  prefix={<SearchOutlined />}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </Col>
              <Col span={6}>
                <Form.Item name="collection">
                  <Select
                    onSelect={(filter: string) =>
                      setCollectionFilters([...collectionFilters, filter])
                    }
                    onDeselect={(filter: string) =>
                      setCollectionFilters(
                        collectionFilters.filter(
                          (collection) => collection !== filter,
                        ),
                      )
                    }
                    mode="tags"
                    placeholder="Filter by collection"
                    allowClear
                    onClear={() => setCollectionFilters([])}
                  >
                    {collections.map((collection) => (
                      <Select.Option
                        key={collection.path}
                        value={collection.path}
                      >
                        {collection.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="orderBy">
                  <Select
                    placeholder="Order by"
                    onChange={(orderBy) => setOrderBy(orderBy as SkyBoxOrderBy)}
                  >
                    {Object.values(SkyBoxOrderBy).map((orderBy) => (
                      <Select.Option key={orderBy} value={orderBy}>
                        {orderBy}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </List.Item>
          <List.Item>
            <SkyBoxList
              filter={filter}
              collections={collections}
              collectionFilters={collectionFilters}
              tags={tags}
              orderBy={orderBy}
            />
          </List.Item>
        </List>
      ) : (
        <Spin size="large" />
      )}
    </Col>
  );
};
