import type { FC } from "react";
import { useEffect, useState } from "react";

import { ClockCircleFilled } from "@ant-design/icons";
import { Badge, Checkbox, DatePicker, Form } from "antd";
import { AxiosError } from "axios";
import dayjs from "dayjs";

import type { Organization } from "@omi-lab/ceos-typescript";
import type { Customer, Subscription } from "@omi-lab/cresus-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

interface Props {
  organization: Organization;
  setWasOnboarded(wasOnboarded: boolean): Promise<void>;
}

export const OrganizationSubscriptionStatus: FC<Props> = (props) => {
  const [customer, setCustomer] = useState<Customer | undefined>();
  const [subscription, setSubscription] = useState<Subscription | undefined>();

  const [customersClient, cresusOrganizationsClient] = useClientsStore(
    (store) => [store.customersClient, store.cresusOrganizationsClient],
  );

  useEffect(() => {
    if (!props.organization.id) {
      return;
    }

    const getCustomer = async () => {
      try {
        await customersClient
          .listCustomers({
            organizationId: props.organization.id,
          })
          .then((response) =>
            setCustomer(response.data.length ? response.data[0] : undefined),
          );
      } catch (error: unknown) {
        showErrorNotification(error);
      }
    };

    getCustomer();
  }, [props.organization.id, customersClient]);

  useEffect(() => {
    if (!props.organization.id) {
      return;
    }

    const getActiveSubscription = async () => {
      try {
        const subscription = await cresusOrganizationsClient
          .getOrganizationActiveSubscription({
            organizationId: props.organization.id,
            returnRelatedQuotaUsages: true,
            returnRelatedCreditUsageSummary: true,
          })
          .then((response) => response.data);
        setSubscription(subscription);
      } catch (error: unknown) {
        if (error instanceof AxiosError && error?.response?.status !== 404) {
          showErrorNotification(error);
        }
      }
    };

    getActiveSubscription();
  }, [props.organization.id, cresusOrganizationsClient]);

  return (
    <Form layout="inline">
      <Form.Item label="Onboarded">
        <Checkbox
          checked={props.organization.wasOnboarded}
          onChange={(e) => props.setWasOnboarded(e.target.checked)}
        />
      </Form.Item>
      <Form.Item label="Customer">
        <Checkbox checked={!!customer} />
      </Form.Item>
      <Form.Item label="Subscription">
        {subscription?.canceledAt ? (
          <Badge count={<ClockCircleFilled style={{ color: "#f5222d" }} />}>
            <Checkbox checked={!!subscription} />
          </Badge>
        ) : (
          <Checkbox checked={!!subscription} />
        )}
      </Form.Item>
      <Form.Item label="Since">
        <DatePicker value={dayjs(subscription?.createdAt)} disabled />
      </Form.Item>
      <Form.Item
        label={
          !subscription?.canceledAt && subscription?.endsAt
            ? "Next payment date"
            : "Subscription ends"
        }
      >
        <DatePicker value={dayjs(subscription?.endsAt)} disabled />
      </Form.Item>
    </Form>
  );
};
