import type { FC } from "react";
import { useEffect, useState } from "react";

import { CloudDownloadOutlined, InfoOutlined } from "@ant-design/icons";
import { Image } from "antd";

import type { Scene } from "@omi-lab/atlas-typescript";
import type { Organization } from "@omi-lab/ceos-typescript";
import type { ModelFile } from "@omi-lab/fedex-typescript";
import type { Render } from "@omi-lab/studio-typescript";
import { RenderType } from "@omi-lab/studio-typescript";

import { useClientsStore } from "../../../store/clients";

import RenderInformationModal from "./RenderInformationModal";

interface Props {
  resource: Render;
  organization?: Organization;
}

const REDIRECTION_TYPE = {
  [RenderType.Photo]: "photos",
  [RenderType.Video]: "videos",
} as const;

type RenderTypeWithoutPrint = "photo" | "video";

const mapRedirectionType = (type: RenderTypeWithoutPrint) =>
  REDIRECTION_TYPE[type];

export const ResourceCard: FC<Props> = (props) => {
  const [, setIsLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState<ModelFile>();
  const [isOpen, setIsOpen] = useState(false);
  const [filesClient] = useClientsStore((state) => [state.filesClient]);
  const scene = (props.resource.scene as Scene) || undefined;

  useEffect(() => {
    if (!props.resource.preview?.outputFileId) {
      setIsLoading(false);
      return;
    }

    const getThumbnail = async () =>
      filesClient.getFile({
        fileId: props.resource.preview!.outputFileId!,
        returnRelatedPresignedURL: true,
      });

    getThumbnail()
      .then((response) => setThumbnail(response.data))
      .then(() => setIsLoading(false));
  }, [setThumbnail, filesClient, props.resource]);

  return (
    <div style={{ position: "relative", background: "white" }}>
      {props.resource.type === RenderType.Photo ? (
        <Image src={thumbnail?.url ?? ""} />
      ) : (
        <video
          style={{ width: "100%" }}
          src={thumbnail?.url ?? ""}
          autoPlay
          loop
        />
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 12,
        }}
      >
        <a
          style={{
            cursor: "pointer",
          }}
          href={`${process.env.REACT_APP_MANAGE_URL}/projects/${
            props.resource.projectId
          }?tab=${mapRedirectionType(props.resource.type as RenderTypeWithoutPrint)}`}
          target="_blank"
          rel="noreferrer"
        >
          <div>
            {new Date(
              props.resource.createdAt as unknown as Date,
            ).toLocaleDateString()}{" "}
          </div>
          <div>{props.organization?.name}</div>
        </a>
        <div style={{ display: "flex", gap: 8 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              right: 12,
              bottom: 12,
              padding: 12,
              width: 20,
              height: 20,
              borderRadius: 20,
              cursor: "pointer",
              background: "white",
              border: "1px solid lightgray",
            }}
          >
            <InfoOutlined
              onClick={() => {
                setIsOpen(true);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              right: 12,
              bottom: 12,
              padding: 12,
              width: 20,
              height: 20,
              borderRadius: 20,
              cursor: "pointer",
              background: "white",
              border: "1px solid lightgray",
            }}
          >
            <CloudDownloadOutlined
              onClick={async () => {
                const dl = await filesClient.getFile({
                  fileId: props.resource.render!.outputFileId!,
                  returnRelatedPresignedURL: true,
                });

                if (dl && dl.data && dl.data.url) {
                  window.open(dl.data.url, "_blank");
                }
              }}
            />
          </div>
        </div>
      </div>

      {scene ? (
        <RenderInformationModal
          key={scene.id}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          scene={scene}
        />
      ) : null}
    </div>
  );
};
