import type { FC } from "react";
import { useState } from "react";

import { Col, Form, Input, Modal, Select, Tabs } from "antd";
import { useFormik } from "formik";

import type {
  CreateProductAddonBody,
  Product,
} from "@omi-lab/cresus-typescript";
import {
  PlanFeatureName,
  ProductSlug,
  ProductType,
  ProductVisibility,
} from "@omi-lab/cresus-typescript";

import { ProductFormPrices } from "src/pkgs/ProductFormPrices";
import {
  productSlugDefaultFeatures,
  productSlugToHumanReadable,
  productTypeToHumanReadable,
} from "src/utils/products";

import { ValidationScheme } from "./CreateAddonModal.schema";

interface Props {
  isOpen: boolean;
  close: () => void;
  product: Product;
  createAddon: (id: string, body: CreateProductAddonBody) => Promise<void>;
}

export const CreateAddonModal: FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    values,
    setValues,
    handleChange,
    handleBlur,
    resetForm,
    errors,
    touched,
    setTouched,
  } = useFormik({
    initialValues: {
      name: "",
      slug: ProductSlug.Videos,
      type: ProductType.SkuAddonUnits,
      visibility: ProductVisibility.Public,
      features: [],
      prices: (props.product.prices || []).map(({ currency }) => ({
        currency,
        unitAmount: 0,
        tiers: [],
      })),
    } as CreateProductAddonBody,
    onSubmit: () => {},
    validateOnMount: false,
    validationSchema: ValidationScheme,
  });

  const createAddon = async () => {
    try {
      setIsLoading(true);

      await props.createAddon(props.product.id, values);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Create addon"
      width="50%"
      open={props.isOpen}
      okButtonProps={{
        disabled: isLoading,
      }}
      onOk={() => createAddon()}
      onCancel={() => {
        resetForm();
        props.close();
      }}
    >
      <Col
        style={{
          backgroundColor: "#fafafa",
          border: "1px dashed #d9d9d9",
          alignItems: "center",
          padding: "30px",
        }}
      >
        <Form initialValues={values}>
          <Form.Item
            label="Name"
            help={touched.name && errors.name}
            validateStatus={touched.name && errors.name ? "error" : "success"}
          >
            <Input
              defaultValue={values.name}
              value={values.name}
              onChange={(e) =>
                setValues({
                  ...values,
                  name: e.target.value,
                })
              }
              onBlur={handleBlur}
            />
          </Form.Item>
          <Form.Item
            label="Slug"
            name="slug"
            help={touched.slug && errors.slug}
            validateStatus={touched.slug && errors.slug ? "error" : "success"}
          >
            <Select
              value={values.slug}
              defaultValue={values.slug}
              onChange={(value) =>
                setValues({
                  ...values,
                  name: productSlugToHumanReadable(value),
                  slug: value,
                  features: productSlugDefaultFeatures(value),
                })
              }
              onBlur={() =>
                setTouched({
                  ...touched,
                  slug: true,
                })
              }
            >
              {[ProductSlug.Videos, ProductSlug._360s, ProductSlug.Print].map(
                (value) => (
                  <Select.Option key={value} value={value}>
                    {productSlugToHumanReadable(value)}
                  </Select.Option>
                ),
              )}
            </Select>
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            help={touched.type && errors.type}
            validateStatus={touched.type && errors.type ? "error" : "success"}
          >
            <Select
              value={values.type}
              defaultValue={values.type}
              onChange={(value) =>
                setValues({
                  ...values,
                  type: value,
                })
              }
              onBlur={() =>
                setTouched({
                  ...touched,
                  type: true,
                })
              }
            >
              {[
                ProductType.SkuAddon,
                ProductType.SkuAddonTiered,
                ProductType.SkuAddonUnits,
              ].map((value) => (
                <Select.Option key={value} value={value}>
                  {productTypeToHumanReadable(value)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Visibility"
            name={`visibility`}
            help={touched.visibility && errors.visibility}
            validateStatus={
              touched.visibility && errors.visibility ? "error" : "success"
            }
          >
            <Select
              value={values.visibility}
              defaultValue={values.visibility}
              onChange={(value) =>
                setValues({
                  ...values,
                  visibility: value,
                })
              }
              onBlur={() =>
                setTouched({
                  ...touched,
                  visibility: true,
                })
              }
            >
              {Object.values(ProductVisibility).map((value) => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Features"
            help={touched.features && errors.features}
            validateStatus={
              touched.features && errors.features ? "error" : "success"
            }
          >
            <Select
              mode="tags"
              onSelect={(name: PlanFeatureName) =>
                setValues({
                  ...values,
                  features: [...(values.features || []), name],
                })
              }
              onDeselect={(name: PlanFeatureName) => {
                setValues({
                  ...values,
                  features: values.features.filter((n) => name !== n),
                });
              }}
              value={values.features as PlanFeatureName[]}
              defaultValue={values.features as PlanFeatureName[]}
            >
              {Object.values(PlanFeatureName).map((value) => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {values.type === ProductType.SkuAddonTiered ? (
            <ProductFormPrices
              prices={values.prices}
              pricesPathPrefix="prices"
            />
          ) : null}

          {values.type === ProductType.SkuAddon ||
          values.type === ProductType.SkuAddonUnits ? (
            <Form.Item label="Prices">
              <Tabs>
                {(values.prices || []).map((price, i) => (
                  <Tabs.TabPane
                    tab={price.currency.toUpperCase()}
                    key={price.currency.toUpperCase()}
                  >
                    <Form.Item
                      label={`${price.currency.toUpperCase()} Unit amount in cents`}
                      name={`prices.${i}.unitAmount`}
                    >
                      <Input
                        disabled={
                          values.prices[i]?.tiers &&
                          values.prices[i].tiers.length > 0
                        }
                        type="number"
                        defaultValue={values.prices[i]?.unitAmount ?? 0}
                        value={values.prices[i]?.unitAmount ?? 0}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Item>
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </Form.Item>
          ) : null}
        </Form>
      </Col>
    </Modal>
  );
};
