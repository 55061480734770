import type { FC } from "react";
import { useState } from "react";

import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Input,
  List,
  notification,
  Pagination,
  Row,
  Select,
  Space,
  Table,
} from "antd";

import type {
  Translation,
  UpdateTextContentBody,
} from "@omi-lab/atlas-typescript";
import { Language } from "@omi-lab/atlas-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";
import { useListTextContents } from "../hooks";
import { TextContentReference } from "../hooks/useListTextContents";

export const Translations: FC = () => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);
  const [isUpdating, setIsUpdating] = useState(false);

  const [nameIncludes, setNameIncludes] = useState<string>();

  const [isReferencedBy, setIsReferencedBy] = useState<TextContentReference>();

  const client = useClientsStore((store) => store.textContentsClient);

  const { textContents, setTextContents, count, isLoading } =
    useListTextContents({
      page,
      pageSize,
      nameIncludes,
      isReferencedBy,
    });

  const updateTranslation = async (
    id: string,
    language: Language,
    translation: string,
  ) => {
    setTextContents((textContents) =>
      textContents.map((textContent) =>
        textContent.id !== id
          ? textContent
          : {
              ...textContent,
              translations: [
                ...(textContent?.translations?.filter(
                  (translation) => translation.languageId !== language,
                ) || []),
                {
                  languageId: language,
                  translation: translation,
                  textContentId: id,
                } as Translation,
              ],
            },
      ),
    );
  };

  const updateTextContent = async (id: string, body: UpdateTextContentBody) => {
    try {
      setIsUpdating(true);
      await client
        .updateTextContent({
          textContentId: id,
          body,
          returnRelatedTranslations: true,
        })
        .then(({ data }) =>
          setTextContents(
            textContents.map((textContent) =>
              textContent.id === id ? data : textContent,
            ),
          ),
        );
      notification.success({
        message: "The text content was successfully updated.",
        duration: 1,
      });
    } catch (error: unknown) {
      showErrorNotification(error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Col style={{ padding: "15px" }}>
      <List grid={{ gutter: 16 }}>
        <List.Item>
          <Row gutter={16} justify="start">
            <Col span={6}>
              <Input
                placeholder="Search for a text"
                prefix={<SearchOutlined />}
                onChange={(e) => setNameIncludes(e.target.value)}
              />
            </Col>
            <Col span={6}>
              <Select
                defaultValue={TextContentReference.ALL}
                style={{ width: 300 }}
                onChange={(value) => setIsReferencedBy(value)}
                options={[
                  { value: TextContentReference.ALL, label: "All" },
                  {
                    value: TextContentReference.SCENE_TEMPLATE_COLLECTION_NAME,
                    label: "Scene Template Collections",
                  },
                  {
                    value: TextContentReference.SCENE_TEMPLATE_NAME,
                    label: "Scene Templates",
                  },
                  {
                    value: TextContentReference.SCENE_TEMPLATE_TAG_NAME,
                    label: "Scene Template Tags",
                  },
                  {
                    value: TextContentReference.SCHEDULED_EVENT,
                    label: "Scheduled Events",
                  },
                  {
                    value: TextContentReference.ANIMATION,
                    label: "Animations",
                  },
                  {
                    value: TextContentReference.CAMERA_COLLECTION,
                    label: "Camera Collections",
                  },
                  {
                    value: TextContentReference.CAMERA,
                    label: "Cameras",
                  },
                  {
                    value: TextContentReference.LIGHT_SET_COLLECTION,
                    label: "Light Set Collections",
                  },
                  {
                    value: TextContentReference.LIGHT_SET,
                    label: "Light Sets",
                  },
                  {
                    value: TextContentReference.MATERIAL_COLLECTION,
                    label: "Material Collections",
                  },
                  {
                    value: TextContentReference.MATERIAL,
                    label: "Materials",
                  },
                  {
                    value: TextContentReference.OBJECT_COLLECTION,
                    label: "Object Collections",
                  },
                  {
                    value: TextContentReference.OBJECT,
                    label: "Objects",
                  },
                  {
                    value: TextContentReference.SKY_BOX_COLLECTION,
                    label: "Skybox Collections",
                  },
                  {
                    value: TextContentReference.SKY_BOX,
                    label: "Skyboxes",
                  },
                  {
                    value: TextContentReference.FORMAT_PRESET_SLUG,
                    label: "Format Presets",
                  },
                  {
                    value: TextContentReference.RATIO_PRESET,
                    label: "Ratio Presets",
                  },
                  {
                    value: TextContentReference.COMPOSITION_PRESET,
                    label: "Composition Presets",
                  },
                  {
                    value: TextContentReference.RATIO_PRESET_GROUP,
                    label: "Ratio Preset Groups",
                  },
                ]}
              />
            </Col>
          </Row>
        </List.Item>
      </List>
      <List grid={{ gutter: 16 }}>
        <List.Item>
          <Table
            dataSource={textContents}
            loading={isLoading}
            pagination={false}
            columns={[
              {
                title: "ID",
                key: "id",
                dataIndex: "id",
                width: "10%",
              },
              {
                title: "Original language",
                key: "originalLanguageId",
                dataIndex: "originalLanguageId",
                width: "10%",
              },
              {
                title: "Original text",
                key: "originalText",
                dataIndex: "originalText",
                width: "10%",
              },
              {
                title: "FR",
                key: "fr",
                render: (_, record) => (
                  <Input
                    type="text"
                    value={
                      record?.translations?.find(
                        ({ languageId }) => languageId === Language.Fr,
                      )?.translation
                    }
                    onChange={(e) =>
                      updateTranslation(record.id, Language.Fr, e.target.value)
                    }
                  />
                ),
                width: "10%",
              },
              {
                title: "EN",
                key: "en",
                render: (_, record) => (
                  <Input
                    type="text"
                    value={
                      record?.translations?.find(
                        ({ languageId }) => languageId === Language.En,
                      )?.translation
                    }
                    onChange={(e) =>
                      updateTranslation(record.id, Language.En, e.target.value)
                    }
                  />
                ),
                width: "10%",
              },
              {
                title: "ES",
                key: "es",
                render: (_, record) => (
                  <Input
                    type="text"
                    value={
                      record?.translations?.find(
                        ({ languageId }) => languageId === Language.Es,
                      )?.translation
                    }
                    onChange={(e) =>
                      updateTranslation(record.id, Language.Es, e.target.value)
                    }
                  />
                ),
                width: "10%",
              },
              {
                title: "Action",
                key: "action",
                render: (_, record) => (
                  <Space>
                    <Button
                      type="primary"
                      disabled={isUpdating}
                      onClick={() =>
                        updateTextContent(record.id, {
                          translations:
                            record.translations?.map((translation) => ({
                              languageId: translation.languageId,
                              translation: translation.translation,
                            })) || [],
                        })
                      }
                    >
                      Save
                    </Button>
                  </Space>
                ),
                width: "5%",
              },
            ]}
          />
          <Divider />
          <Pagination
            current={page}
            onChange={setPage}
            pageSize={pageSize}
            total={count}
            onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
            showSizeChanger
          />
        </List.Item>
      </List>
    </Col>
  );
};
