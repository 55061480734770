import type { FC } from "react";
import { useMemo, useState } from "react";

import { Col, DatePicker, Divider, List, Pagination, Row } from "antd";

import { OrganizationSearch } from "../../Billing/components/OrganizationSearch";
import { ResourceCard } from "../components/ResourceCard";
import { useListOrganizations } from "../hooks/useListOrganizations";
import { useListRenders } from "../hooks/useListRenders";

const { RangePicker } = DatePicker;

export const OrganizationsRender: FC = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [blackList, setBlackList] = useState<string[]>([]);
  const [whiteList, setWhiteList] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<string[]>([]);

  const { renders, pageCount } = useListRenders({
    page,
    pageSize,
    blackList,
    whiteList,
    dateRange,
  });

  const organizationIds = useMemo(() => {
    return Object.keys(
      renders.reduce(
        (acc, { organizationId }) => ({ ...acc, [organizationId!]: true }),
        {},
      ),
    );
  }, [renders]);

  const { organizations } = useListOrganizations(organizationIds);

  return (
    <Col style={{ padding: "15px" }}>
      <List grid={{ gutter: 16 }}>
        <List.Item>
          <Row gutter={2}>
            <Col span={7}>
              <OrganizationSearch
                placeholder="Organizations to filter out"
                onSelect={(e) =>
                  setBlackList([...blackList.filter((o) => o !== e), e])
                }
                onDeselect={(e) =>
                  setBlackList([...blackList.filter((o) => o !== e)])
                }
                onClear={() => setBlackList([])}
              />
            </Col>
            <Col span={7}>
              <OrganizationSearch
                placeholder="Organizations to filter in"
                onSelect={(e) =>
                  setWhiteList([...blackList.filter((o) => o !== e), e])
                }
                onDeselect={(e) =>
                  setWhiteList([...blackList.filter((o) => o !== e)])
                }
                onClear={() => setWhiteList([])}
              />
            </Col>

            <Col span={7}>
              <RangePicker
                onChange={(_, info) => {
                  setDateRange(info);
                }}
              />
            </Col>
          </Row>
        </List.Item>
        <List.Item>
          <Row gutter={[12, 12]}>
            {renders
              ?.filter(({ preview }) => preview?.status === 3)
              .map((render) => (
                <Col key={render.id} span={4}>
                  <ResourceCard
                    organization={organizations[render.organizationId!]}
                    resource={render}
                  />
                </Col>
              ))}
          </Row>
          <Divider />
          <Pagination
            current={page}
            onChange={setPage}
            total={pageCount * pageSize}
            onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
            showSizeChanger
          />
        </List.Item>
      </List>
    </Col>
  );
};
