import { Col, Form, Input, Select, Space } from "antd";
import { useFormikContext } from "formik";
import cloneDeep from "lodash.clonedeep";

import type { CreateProductBody } from "@omi-lab/cresus-typescript";
import {
  Currency,
  IntervalUnit,
  PlanFeatureName,
  ProductSlug,
  ProductType,
  ProductVisibility,
} from "@omi-lab/cresus-typescript";

import { ProductFormPrices } from "src/pkgs/ProductFormPrices";
import {
  productSlugToHumanReadable,
  productTypeToHumanReadable,
} from "src/utils/products";

import { useListCoupons } from "../../Organizations/hooks/useListCoupons";

type DetailsProps = {
  disabled?: boolean;
};

export const Details = ({ disabled }: DetailsProps) => {
  const { coupons, isLoading: isLoadingCoupons } = useListCoupons({
    page: 1,
    pageSize: 50,
  });

  const {
    values,
    handleChange,
    setValues,
    errors,
    touched,
    handleBlur,
    setTouched,
    setFieldValue,
  } = useFormikContext<CreateProductBody>();

  return (
    <Col
      style={{
        backgroundColor: "#fafafa",
        border: "1px dashed #d9d9d9",
        alignItems: "center",
        padding: "30px",
      }}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        initialValues={values}
      >
        <Form.Item
          label="Name"
          name="name"
          help={touched.name && errors.name}
          validateStatus={touched.name && errors.name ? "error" : "success"}
        >
          <Input
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item
          label="Slug"
          name="slug"
          help={touched.slug && errors.slug}
          validateStatus={touched.slug && errors.slug ? "error" : "success"}
        >
          <Select value={values.slug} defaultValue={values.slug} disabled>
            {[ProductSlug.PerSku].map((value) => (
              <Select.Option key={value} value={value}>
                {productSlugToHumanReadable(value)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          help={touched.type && errors.type}
          validateStatus={touched.type && errors.type ? "error" : "success"}
        >
          <Select
            disabled
            value={values.type}
            defaultValue={values.type}
            onChange={(value) =>
              setValues({
                ...values,
                type: value,
              })
            }
            onBlur={() =>
              setTouched({
                ...touched,
                type: true,
              })
            }
          >
            {Object.values(ProductType).map((value) => (
              <Select.Option key={value} value={value}>
                {productTypeToHumanReadable(value)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Visibility"
          name="visibility"
          help={touched.visibility && errors.visibility}
          validateStatus={
            touched.visibility && errors.visibility ? "error" : "success"
          }
        >
          <Select
            disabled={disabled}
            value={values.visibility}
            onChange={(value) =>
              setValues({
                ...values,
                visibility: value,
              })
            }
            onBlur={() =>
              setTouched({
                ...touched,
                visibility: true,
              })
            }
          >
            {Object.values(ProductVisibility).map((value) => (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Billing interval">
          <Space>
            <Form.Item
              name="billingInterval"
              help={touched.billingInterval && errors.billingInterval}
              validateStatus={
                touched.billingInterval && errors.billingInterval
                  ? "error"
                  : "success"
              }
            >
              <Input
                disabled={disabled}
                type="number"
                value={values.billingInterval}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
            <Form.Item
              name="billingIntervalUnit"
              help={touched.billingIntervalUnit && errors.billingIntervalUnit}
              validateStatus={
                touched.billingIntervalUnit && errors.billingIntervalUnit
                  ? "error"
                  : "success"
              }
            >
              <Select
                disabled={disabled}
                value={values.billingIntervalUnit!}
                onChange={(value) =>
                  setValues({
                    ...values,
                    billingIntervalUnit: value,
                  })
                }
                onBlur={() =>
                  setTouched({
                    ...touched,
                    billingIntervalUnit: true,
                  })
                }
              >
                {Object.values(IntervalUnit).map((value) => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label="Commitment interval">
          <Space>
            <Form.Item
              name="commitmentInterval"
              help={touched.commitmentInterval && errors.commitmentInterval}
              validateStatus={
                touched.commitmentInterval && errors.commitmentInterval
                  ? "error"
                  : "success"
              }
            >
              <Input
                disabled={disabled}
                type="number"
                value={values.commitmentInterval!}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
            <Form.Item
              name="commitmentIntervalUnit"
              help={
                touched.commitmentIntervalUnit && errors.commitmentIntervalUnit
              }
              validateStatus={
                touched.commitmentIntervalUnit && errors.commitmentIntervalUnit
                  ? "error"
                  : "success"
              }
            >
              <Select
                disabled={disabled}
                value={values.commitmentIntervalUnit!}
                onChange={(value) =>
                  setValues({
                    ...values,
                    commitmentIntervalUnit: value,
                  })
                }
                onBlur={() =>
                  setTouched({
                    ...touched,
                    commitmentIntervalUnit: true,
                  })
                }
              >
                {Object.values(IntervalUnit).map((value) => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item
          label="Features"
          name="features"
          help={touched.features && errors.features}
          validateStatus={
            touched.features && errors.features ? "error" : "success"
          }
        >
          <Select
            disabled={disabled}
            mode="tags"
            onSelect={(name: PlanFeatureName) =>
              setValues({
                ...values,
                features: [...(values.features || []), name],
              })
            }
            onDeselect={(name: PlanFeatureName) => {
              setValues({
                ...values,
                features: (values.features || []).filter(
                  (feature: string) => feature !== name,
                ),
              });
            }}
            onBlur={handleBlur}
          >
            {Object.values(PlanFeatureName).map((feature) => (
              <Select.Option key={feature} value={feature}>
                {feature}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Coupon">
          <Select
            disabled={disabled}
            value={values.defaultCouponId || undefined}
            allowClear
            onClear={() => {
              setValues((values) => ({
                ...values,
                couponId: undefined,
              }));
            }}
            loading={isLoadingCoupons}
            onChange={(option) => {
              setValues((values) => ({
                ...values,
                defaultCouponId: option as string,
              }));
            }}
          >
            {coupons.map((coupon) => (
              <Select.Option value={coupon.id} key={coupon.id}>
                {coupon.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Currencies" name="currencies">
          <Select
            disabled={disabled}
            mode="tags"
            onSelect={(currency: Currency) =>
              setFieldValue("prices", [
                ...cloneDeep(values.prices || []),
                { currency, unitAmount: null, tiers: [] },
              ])
            }
            onDeselect={(currency: Currency) =>
              setFieldValue(
                "prices",
                [...cloneDeep(values.prices || [])].filter(
                  (price) => price.currency !== currency,
                ),
              )
            }
            onBlur={handleBlur}
          >
            {Object.values(Currency).map((currency) => (
              <Select.Option key={currency} value={currency}>
                {currency.toUpperCase()}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <ProductFormPrices
          prices={values.prices}
          pricesPathPrefix="prices"
          disabled={disabled}
        />
      </Form>
    </Col>
  );
};
