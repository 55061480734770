import type { FC } from "react";
import { useEffect, useState } from "react";

import { ReloadOutlined } from "@ant-design/icons";
import { Badge, Card, Image, Skeleton, Space } from "antd";

import type { SceneTemplateCustomPreview } from "@omi-lab/atlas-typescript";
import { ScenePreviewStatus } from "@omi-lab/atlas-typescript";
import type { ModelFile } from "@omi-lab/fedex-typescript";

import { useClientsStore } from "../../../store/clients";

import { TemplateCustomPreviewConfirmWorkflowReschedulingModal } from "./TemplateCustomPreviewConfirmWorkflowReschedulingModal";

type Props = {
  preview: SceneTemplateCustomPreview;
  rescheduleWorkflow: (workflowId: string) => Promise<void>;
  rescheduledWorkflows: string[];
};

const StatusToColorMap: Record<ScenePreviewStatus, string> = {
  [ScenePreviewStatus.NUMBER_0]: "grey",
  [ScenePreviewStatus.NUMBER_1]: "blue",
  [ScenePreviewStatus.NUMBER_2]: "red",
  [ScenePreviewStatus.NUMBER_3]: "green",
};

const StatusToTextMap: Record<ScenePreviewStatus, string> = {
  [ScenePreviewStatus.NUMBER_0]: "Pending",
  [ScenePreviewStatus.NUMBER_1]: "In progress",
  [ScenePreviewStatus.NUMBER_2]: "Failed",
  [ScenePreviewStatus.NUMBER_3]: "Success",
};

export const TemplateCustomPreviewCard: FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState<ModelFile>();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const filesClient = useClientsStore((state) => state.filesClient);

  useEffect(() => {
    if (!props.preview.outputFileId) {
      setIsLoading(false);
      return;
    }

    const getThumbnail = async () =>
      filesClient.getFile({
        fileId: props.preview.outputFileId!,
        returnRelatedPresignedURL: true,
      });

    getThumbnail()
      .then((response) => setThumbnail(response.data))
      .then(() => setIsLoading(false));
  }, [setThumbnail, filesClient, props.preview.outputFileId]);

  return (
    <>
      <Badge.Ribbon
        text={StatusToTextMap[props.preview.status]}
        color={StatusToColorMap[props.preview.status]}
      >
        <Card
          key={props.preview.id}
          style={{ border: "2px solid lightgrey" }}
          hoverable={false}
          actions={[
            <ReloadOutlined
              key="edit"
              onClick={() => setIsModalVisible(true)}
            />,
          ]}
        >
          <Skeleton loading={isLoading} avatar active>
            <Space
              style={{
                width: "100%",
                height: "auto",
                overflowY: "scroll",
              }}
              direction="vertical"
              size="large"
            >
              <Image src={thumbnail?.url ?? ""} />
              <Card.Meta
                title={<span>{props.preview.sceneTemplate?.name}</span>}
              />
            </Space>
          </Skeleton>
        </Card>
      </Badge.Ribbon>
      {isModalVisible && (
        <TemplateCustomPreviewConfirmWorkflowReschedulingModal
          key={props.preview.id}
          close={() => setIsModalVisible(false)}
          isVisible={isModalVisible}
          workflowId={props.preview.workflowId!}
          hasBeenRescheduled={props.rescheduledWorkflows.includes(
            props.preview.workflowId!,
          )}
          rescheduleWorkflow={props.rescheduleWorkflow}
        />
      )}
    </>
  );
};
