import { useEffect, useState } from "react";

import type { ObjectCollection } from "@omi-lab/atlas-typescript";

import { showErrorNotification } from "src/utils/error";

import { useClientsStore } from "../../../store/clients";

export function useSearchCollections() {
  const [search, setSearch] = useState<undefined | string>();
  const [collections, setCollections] = useState<ObjectCollection[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [objectCollectionsClient] = useClientsStore((store) => [
    store.objectCollectionsClient,
  ]);

  useEffect(() => {
    if (search === undefined) return;
    (async () => {
      setIsLoading(true);
      try {
        await objectCollectionsClient
          .listObjectCollections({
            includes: search,
            visibility: "private",
            page: 1,
            pageSize: 50,
          })
          .then((response) => setCollections(response.data));
      } catch (error: unknown) {
        showErrorNotification(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [objectCollectionsClient, search]);

  return { collections, search, setSearch, isLoading };
}
