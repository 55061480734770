import { useEffect } from "react";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Space, Tabs } from "antd";
import { useFormikContext } from "formik";
import cloneDeep from "lodash.clonedeep";

import type {
  CreatePriceBody,
  CreateProductBody,
} from "@omi-lab/cresus-typescript";

type ProductFormPricesProps = {
  pricesPathPrefix: string;
  prices: CreatePriceBody[] | undefined;
  disabled?: boolean;
};

export const ProductFormPrices = ({
  pricesPathPrefix,
  prices,
  disabled,
}: ProductFormPricesProps) => {
  const { handleChange, handleBlur, setFieldValue } =
    useFormikContext<CreateProductBody>();

  const pricesArrayKey = JSON.stringify(prices);

  useEffect(() => {
    if (
      prices &&
      prices.some((price) => price?.tiers?.at(-1)?.upTo !== undefined)
    ) {
      setFieldValue(
        pricesPathPrefix,
        prices.map((price) => ({
          ...price,
          tiers: price?.tiers?.map((tier, index) =>
            index === price?.tiers!.length - 1
              ? { ...tier, upTo: undefined }
              : tier,
          ),
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricesArrayKey, setFieldValue, pricesPathPrefix]);

  return (
    <Form.Item label="Prices">
      <Tabs>
        {(prices || []).map((price, priceIndex) => (
          <Tabs.TabPane tab={price.currency.toUpperCase()} key={price.currency}>
            {price.tiers?.map((tier, tierIndex) => (
              <Form.Item
                key={`price-${priceIndex}-tier-${tierIndex}`}
                label={`[${price.currency.toUpperCase()}] Tier #${tierIndex}`}
              >
                <Space>
                  <Col style={{ minWidth: "250px" }}>
                    <label>Up to :</label>
                    <Input
                      disabled={
                        disabled || tierIndex === price.tiers!.length - 1
                      }
                      placeholder={
                        tierIndex === price.tiers!.length - 1 ? "∞" : undefined
                      }
                      name={`${pricesPathPrefix}.${priceIndex}.tiers.${tierIndex}.upTo`}
                      type="number"
                      value={tier.upTo!}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label>Unit amount in cents :</label>
                    <Input
                      disabled={disabled}
                      name={`${pricesPathPrefix}.${priceIndex}.tiers.${tierIndex}.unitAmount`}
                      type="number"
                      value={tier.unitAmount!}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                  {!disabled ? (
                    <MinusCircleOutlined
                      disabled={disabled}
                      title="Remove tier"
                      onClick={() =>
                        setFieldValue(
                          `${pricesPathPrefix}.${priceIndex}.tiers`,
                          cloneDeep(price.tiers || []).filter(
                            (_, idy) => idy !== tierIndex,
                          ),
                        )
                      }
                    />
                  ) : null}
                  <Divider />
                </Space>
              </Form.Item>
            ))}
            {!disabled ? (
              <Button
                disabled={disabled}
                type="dashed"
                onClick={() =>
                  setFieldValue(`${pricesPathPrefix}.${priceIndex}.tiers`, [
                    ...cloneDeep(price.tiers || []),
                    {
                      upTo: undefined,
                      unitAmount: 100,
                    },
                  ])
                }
                block
                icon={<PlusOutlined />}
              >
                Add a new tier
              </Button>
            ) : null}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Form.Item>
  );
};
