import type { FC } from "react";
import { useEffect, useState } from "react";

import { SearchOutlined } from "@ant-design/icons";
import { Col, Form, Input, List, Row, Select, Spin } from "antd";

import type {
  LightSetCollection as ILightSetCollection,
  LightSetTag,
} from "@omi-lab/atlas-typescript";
import { LightSetOrderBy } from "@omi-lab/atlas-typescript";

import { useClientsStore } from "../../../store/clients";
import { LightSetList } from "../components/LightSetList";

function getCollectionLeaves(
  collection: ILightSetCollection,
): ILightSetCollection[] {
  function traverse(
    acc: ILightSetCollection[],
    node: ILightSetCollection,
  ): ILightSetCollection[] {
    if (node.collections?.length) return node.collections.reduce(traverse, acc);
    acc.push(node);
    return acc;
  }

  return traverse([], collection);
}

export const LightSets: FC = (props) => {
  const [collections, setCollections] = useState<ILightSetCollection[]>([]);
  const [tags, setTags] = useState<LightSetTag[]>([]);
  const [filter, setFilter] = useState("");
  const [orderBy, setOrderBy] = useState<LightSetOrderBy>();
  const [collectionFilters, setCollectionFilters] = useState<string[]>([]);

  const [lightSetCollectionsClient, lightSetTagsClient] = useClientsStore(
    (state) => [state.lightSetCollectionsClient, state.lightSetTagsClient],
  );

  useEffect(() => {
    const getCollections = async () =>
      lightSetCollectionsClient.listLightSetCollections();

    getCollections()
      .then((response) => response.data)
      .then((collections) =>
        collections.reduce(
          (acc, next) => [...acc, ...getCollectionLeaves(next)],
          [] as ILightSetCollection[],
        ),
      )
      .then((collections) => setCollections(collections));
  }, [lightSetCollectionsClient, setCollections]);

  useEffect(() => {
    (async () => {
      lightSetTagsClient
        .listLightSetTags()
        .then((response) => response.data)
        .then((tags) => setTags(tags));
    })();
  }, [lightSetTagsClient, setTags]);

  return (
    <Col style={{ padding: "15px" }}>
      {collections ? (
        <List grid={{ gutter: 16 }}>
          <List.Item>
            <Row gutter={16} justify="space-between">
              <Col span={12}>
                <Input
                  placeholder="Search for a lightSet"
                  prefix={<SearchOutlined />}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </Col>
              <Col span={6}>
                <Form.Item name="collection">
                  <Select
                    onSelect={(filter: string) =>
                      setCollectionFilters([...collectionFilters, filter])
                    }
                    onDeselect={(filter: string) =>
                      setCollectionFilters(
                        collectionFilters.filter(
                          (collection) => collection !== filter,
                        ),
                      )
                    }
                    mode="tags"
                    placeholder="Filter by collection"
                    allowClear
                    onClear={() => setCollectionFilters([])}
                  >
                    {collections.map((collection) => (
                      <Select.Option
                        key={collection.path}
                        value={collection.path}
                      >
                        {collection.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="orderBy">
                  <Select
                    placeholder="Order by"
                    onChange={(orderBy) =>
                      setOrderBy(orderBy as LightSetOrderBy)
                    }
                  >
                    {Object.values(LightSetOrderBy).map((orderBy) => (
                      <Select.Option key={orderBy} value={orderBy}>
                        {orderBy}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </List.Item>
          <List.Item>
            <LightSetList
              {...props}
              filter={filter}
              collectionFilters={collectionFilters}
              collections={collections}
              tags={tags}
              orderBy={orderBy}
            />
          </List.Item>
        </List>
      ) : (
        <Spin size="large" />
      )}
    </Col>
  );
};
