import type { Collection } from "../types";

export function append(
  collections: Collection[],
  pathPrefix: string,
  toAppend: Collection,
): Collection[] {
  if (pathPrefix === "") {
    return [...collections, toAppend];
  }

  return collections.map((collection) => ({
    ...collection,
    collections:
      collection.path === pathPrefix
        ? [...(collection.collections || []), toAppend]
        : append(collection.collections || [], pathPrefix, toAppend),
  }));
}

export function find(
  collections: Collection[],
  path: string,
): Collection | undefined {
  return collections.reduce((acc: Collection | undefined, next) => {
    if (acc) {
      return acc;
    }

    if (next.path === path) {
      return next;
    }

    return find(next.collections || [], path);
  }, undefined);
}

export function removePaths(
  collections: Collection[],
  paths: string[],
): Collection[] {
  return collections
    .filter((collection) => !paths.includes(collection.path))
    .map((collection) => ({
      ...collection,
      collections: removePaths(
        collection.collections?.filter(({ path }) => !paths.includes(path)) ||
          [],
        paths,
      ),
    }));
}
