import type { FC } from "react";
import { useEffect, useState } from "react";

import { SearchOutlined } from "@ant-design/icons";
import { Checkbox, Col, Form, Input, List, Row, Select, Spin } from "antd";

import type {
  ObjectCollection as IObjectCollection,
  ObjectTag,
} from "@omi-lab/atlas-typescript";
import { ObjectOrderBy, ObjectVisibility } from "@omi-lab/atlas-typescript";
import type { Organization } from "@omi-lab/ceos-typescript";

import { useClientsStore } from "../../../store/clients";
import { ObjectList } from "../components/ObjectList";

function getCollectionLeaves(
  collection: IObjectCollection,
): IObjectCollection[] {
  function traverse(
    acc: IObjectCollection[],
    node: IObjectCollection,
  ): IObjectCollection[] {
    if (node.collections?.length) return node.collections.reduce(traverse, acc);
    acc.push(node);
    return acc;
  }

  return traverse([], collection);
}

export const Objects: FC = (props) => {
  const [collections, setCollections] = useState<IObjectCollection[]>([]);
  const [tags, setTags] = useState<ObjectTag[]>([]);
  const [organizationId, setOrganizationId] = useState<string>();
  const [organizations, setOrganizations] = useState([] as Organization[]);
  const [isArchived, setIsArchived] = useState(false);

  const [orderBy, setOrderBy] = useState<ObjectOrderBy>();
  const [organizationsFilter, setOrganizationsFilter] = useState("");
  const [visibilityFilters, setVisibilityFilters] = useState([
    "public",
    "private",
    "hidden",
  ]);

  const [filter, setFilter] = useState("");
  const [tagFilters, setTagFilters] = useState<string[]>();
  const [collectionFilter, setCollectionFilter] = useState<string>();
  const [tagNameFilter, setTagNameFilter] = useState<string>();

  const { objectCollectionsClient, objectTagsClient, organizationsClient } =
    useClientsStore((state) => state);

  useEffect(() => {
    const getCollections = async () =>
      objectCollectionsClient.listObjectCollections();

    getCollections()
      .then((response) => response.data)
      .then((collections) =>
        collections.reduce(
          (acc, next) => [...acc, ...getCollectionLeaves(next)],
          [] as IObjectCollection[],
        ),
      )
      .then((collections) => setCollections(collections));
  }, [objectCollectionsClient, setCollections]);

  useEffect(() => {
    const listTags = async () =>
      objectTagsClient.listObjectTags({
        includes: tagNameFilter,
        page: 1,
        pageSize: 30,
      });

    listTags().then((response) => setTags(response.data));
  }, [objectTagsClient, setTags, tagNameFilter]);

  useEffect(() => {
    const listOrganizations = () =>
      organizationsClient
        .listOrganizations({
          page: 1,
          pageSize: 50,
          nameIncludes: organizationsFilter,
        })
        .then((response) => setOrganizations(response.data));

    listOrganizations();
  }, [organizationsFilter, setOrganizations, organizationsClient]);

  return (
    <Col style={{ padding: "15px" }}>
      {collections ? (
        <List grid={{ gutter: 2 }}>
          <List.Item>
            <Row gutter={14} justify="space-between">
              <Col span={7}>
                <Form.Item name="search">
                  <Input
                    placeholder="Search for an object"
                    prefix={<SearchOutlined />}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="organizations">
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Search for an organization"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(value) => setOrganizationsFilter(value)}
                    onChange={(value) => setOrganizationId(value as string)}
                    value={organizationId}
                  >
                    {organizations.map((organization) => (
                      <Select.Option
                        key={organization.id}
                        value={organization.id}
                      >
                        {organization.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Visibility"
                  onSelect={(name: string) => {
                    setVisibilityFilters(
                      visibilityFilters ? [...visibilityFilters, name] : [name],
                    );
                    setTagNameFilter(undefined);
                  }}
                  onDeselect={(name) => {
                    setVisibilityFilters(
                      visibilityFilters?.filter((tag: string) => tag !== name),
                    );
                    setTagNameFilter(undefined);
                  }}
                  value={visibilityFilters}
                  style={{ width: "100%" }}
                >
                  {Object.values(ObjectVisibility).map((visibility) => (
                    <Select.Option key={visibility} value={visibility!}>
                      {visibility}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col span={4}>
                <Form.Item name="tags">
                  <Select
                    showSearch
                    mode="tags"
                    placeholder="Search by tags"
                    onSelect={(name: string) => {
                      setTagFilters(
                        tagFilters ? [...tagFilters, name] : [name],
                      );
                      setTagNameFilter(undefined);
                    }}
                    onDeselect={(name) => {
                      setTagFilters(
                        tagFilters?.filter((tag: string) => tag !== name),
                      );
                      setTagNameFilter(undefined);
                    }}
                    onSearch={(value) => setTagNameFilter(value)}
                  >
                    {tags.map((tag) => (
                      <Select.Option key={tag.name} value={tag.name!}>
                        {tag.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name="collection">
                  <Select
                    onSelect={(filter: string) => setCollectionFilter(filter)}
                    placeholder="Filter by collection"
                    allowClear
                    onClear={() => setCollectionFilter(undefined)}
                  >
                    {collections.map((collection) => (
                      <Select.Option
                        key={collection.path}
                        value={collection.path}
                      >
                        {collection.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name="isArchived" label="Archived">
                  <Checkbox
                    checked={isArchived}
                    onChange={(e) => setIsArchived(e.target.checked)}
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name="orderBy">
                  <Select
                    placeholder="Order by"
                    onChange={(orderBy) => setOrderBy(orderBy as ObjectOrderBy)}
                  >
                    {Object.values(ObjectOrderBy).map((orderBy) => (
                      <Select.Option key={orderBy} value={orderBy}>
                        {orderBy}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </List.Item>
          <List.Item>
            <ObjectList
              organizationId={organizationId}
              visibility={visibilityFilters}
              {...props}
              filter={filter}
              orderBy={orderBy}
              tag={tagFilters}
              collectionFilter={collectionFilter}
              collections={collections}
              tags={tags}
              isArchived={isArchived}
            />
          </List.Item>
        </List>
      ) : (
        <Spin size="large" />
      )}
    </Col>
  );
};
