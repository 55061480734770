import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Select, Tabs } from "antd";
import { ErrorMessage, useFormikContext } from "formik";
import has from "lodash.has";

import type { CreateProductBody } from "@omi-lab/cresus-typescript";
import {
  PlanFeatureName,
  ProductSlug,
  ProductType,
  ProductVisibility,
} from "@omi-lab/cresus-typescript";

import { ProductFormPrices } from "src/pkgs/ProductFormPrices";
import {
  productSlugDefaultFeatures,
  productSlugToHumanReadable,
  productTypeToHumanReadable,
} from "src/utils/products";

type AddonsProps = {
  disabled?: boolean;
};

export const Addons = ({ disabled }: AddonsProps) => {
  const { values, handleChange, setValues, errors, touched, handleBlur } =
    useFormikContext<CreateProductBody>();

  return (
    <Col
      style={{
        backgroundColor: "#fafafa",
        border: "1px dashed #d9d9d9",
        alignItems: "center",
        padding: "30px",
      }}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        initialValues={values}
      >
        {(values.addons || []).map((addon, addonIndex) => (
          <>
            <Form.Item
              label="Name"
              help={<ErrorMessage name={`addons.${addonIndex}.name`} />}
              validateStatus={
                touched.addons &&
                errors.addons &&
                errors.addons[addonIndex] &&
                has(errors, `addons.${addonIndex}.name`)
                  ? "error"
                  : "success"
              }
            >
              <Input
                disabled={disabled}
                defaultValue={addon.name}
                value={addon.name}
                onChange={(e) =>
                  setValues({
                    ...values,
                    addons: (values.addons || []).map((a, i) => {
                      if (i === addonIndex) {
                        return {
                          ...a,
                          name: e.target.value,
                        };
                      }
                      return a;
                    }),
                  })
                }
                onBlur={handleBlur}
              />
            </Form.Item>
            <Form.Item
              label="Slug"
              name={`addons.${addonIndex}.slug`}
              help={<ErrorMessage name={`addons.${addonIndex}.slug`} />}
              validateStatus={
                touched.addons &&
                errors.addons &&
                errors.addons[addonIndex] &&
                has(errors, `addons.${addonIndex}.slug`)
                  ? "error"
                  : "success"
              }
            >
              <Select
                disabled={disabled}
                value={addon.slug}
                defaultValue={addon.slug}
                onChange={(value) =>
                  setValues({
                    ...values,
                    addons: (values.addons || []).map((a, i) => {
                      if (i === addonIndex) {
                        return {
                          ...a,
                          name: productSlugToHumanReadable(value),
                          slug: value,
                          features: productSlugDefaultFeatures(value),
                        };
                      }
                      return a;
                    }),
                  })
                }
              >
                {[ProductSlug.Videos, ProductSlug._360s, ProductSlug.Print].map(
                  (value) => (
                    <Select.Option key={value} value={value}>
                      {productSlugToHumanReadable(value)}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label="Type"
              name={`addons.${addonIndex}.type`}
              help={<ErrorMessage name={`addons.${addonIndex}.type`} />}
              validateStatus={
                touched.addons &&
                errors.addons &&
                errors.addons[addonIndex] &&
                has(errors, `addons.${addonIndex}.type`)
                  ? "error"
                  : "success"
              }
            >
              <Select
                disabled={disabled}
                value={addon.type}
                defaultValue={addon.type}
                onChange={(value) =>
                  setValues({
                    ...values,
                    addons: (values.addons || []).map((a, i) => {
                      if (i === addonIndex) {
                        return { ...a, type: value };
                      }
                      return a;
                    }),
                  })
                }
              >
                {[
                  ProductType.SkuAddon,
                  ProductType.SkuAddonTiered,
                  ProductType.SkuAddonUnits,
                ].map((value) => (
                  <Select.Option key={value} value={value}>
                    {productTypeToHumanReadable(value)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Visibility"
              name={`addons.${addonIndex}.visibility`}
              help={<ErrorMessage name={`addons.${addonIndex}.visibility`} />}
              validateStatus={
                touched.addons &&
                errors.addons &&
                errors.addons[addonIndex] &&
                has(errors, `addons.${addonIndex}.visibility`)
                  ? "error"
                  : "success"
              }
            >
              <Select
                disabled
                value={addon.visibility}
                defaultValue={addon.visibility}
                onChange={(value) =>
                  setValues({
                    ...values,
                    addons: (values.addons || []).map((a, i) => {
                      if (i === addonIndex) {
                        return { ...a, visibility: value };
                      }
                      return a;
                    }),
                  })
                }
              >
                {Object.values(ProductVisibility).map((value) => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Features"
              help={<ErrorMessage name={`addons.${addonIndex}.features`} />}
              validateStatus={
                touched.addons &&
                errors.addons &&
                errors.addons[addonIndex] &&
                has(errors, `addons.${addonIndex}.features`)
                  ? "error"
                  : "success"
              }
            >
              <Select
                disabled={disabled}
                mode="tags"
                onSelect={(name: PlanFeatureName) =>
                  setValues({
                    ...values,
                    addons: (values.addons || []).map((a, i) => {
                      if (i === addonIndex) {
                        return {
                          ...a,
                          features: [...(addon.features || []), name],
                        };
                      }
                      return a;
                    }),
                  })
                }
                onDeselect={() => {
                  setValues({
                    ...values,
                    addons: (values.addons || []).map((a, i) => {
                      if (i === addonIndex) {
                        return {
                          ...a,
                          features: addon.features.filter((_, j) => i !== j),
                        };
                      }
                      return a;
                    }),
                  });
                }}
                value={addon.features as PlanFeatureName[]}
                defaultValue={addon.features as PlanFeatureName[]}
              >
                {Object.values(PlanFeatureName).map((value) => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {addon.type === ProductType.SkuAddonTiered ? (
              <ProductFormPrices
                disabled={disabled}
                prices={addon.prices}
                pricesPathPrefix={`addons.${addonIndex}.prices`}
              />
            ) : null}
            {addon.type === ProductType.SkuAddon ||
            addon.type === ProductType.SkuAddonUnits ? (
              <Form.Item label="Prices">
                <Tabs tabPosition="left">
                  {(addon.prices || []).map((price, i) => (
                    <Tabs.TabPane
                      tab={price.currency.toUpperCase()}
                      key={price.currency.toUpperCase()}
                    >
                      <Form.Item
                        label={`${price.currency.toUpperCase()} Unit amount in cents`}
                        name={`addons.${addonIndex}.prices.${i}.unitAmount`}
                        help={
                          <ErrorMessage
                            name={`addons.${addonIndex}.prices.${i}.unitAmount`}
                          />
                        }
                        validateStatus={
                          touched.addons &&
                          errors.addons &&
                          errors.addons[addonIndex] &&
                          has(
                            errors,
                            `addons.${addonIndex}.prices.${i}.unitAmount`,
                          )
                            ? "error"
                            : "success"
                        }
                      >
                        <Input
                          disabled={
                            disabled ||
                            (addon.prices[i]?.tiers &&
                              addon.prices[i].tiers.length > 0)
                          }
                          type="number"
                          defaultValue={addon.prices[i]?.unitAmount ?? 0}
                          value={addon.prices[i]?.unitAmount ?? 0}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                    </Tabs.TabPane>
                  ))}
                </Tabs>
              </Form.Item>
            ) : null}
            {!disabled ? (
              <MinusCircleOutlined
                disabled={disabled}
                onClick={() =>
                  setValues({
                    ...values,
                    addons: (values.addons || []).filter(
                      (_, idx) => idx !== addonIndex,
                    ),
                  })
                }
              />
            ) : null}
            <Divider />
          </>
        ))}
        {!disabled ? (
          <Form.Item>
            <Button
              disabled={disabled}
              type="dashed"
              onClick={() =>
                setValues({
                  ...values,
                  addons: [
                    ...(values.addons || []),
                    {
                      name: "",
                      slug: ProductSlug.Videos,
                      type: ProductType.SkuAddon,
                      visibility: ProductVisibility.Public,
                      features: [],
                      prices: values.prices.map(({ currency }) => ({
                        currency,
                        unitAmount: 0,
                        tiers: [],
                      })),
                    },
                  ],
                })
              }
              block
              icon={<PlusOutlined />}
            >
              Add
            </Button>
          </Form.Item>
        ) : null}
      </Form>
    </Col>
  );
};
