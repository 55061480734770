import type { FC } from "react";
import { useEffect, useState } from "react";

import { DeleteOutlined } from "@ant-design/icons";
import {
  Card,
  Image,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Tooltip,
} from "antd";

import type {
  Material,
  MaterialCollection,
  MaterialTag,
  UpdateMaterialBody,
} from "@omi-lab/atlas-typescript";
import type { ModelFile } from "@omi-lab/fedex-typescript";

import { useClientsStore } from "../../../store/clients";

type Props = {
  material: Material;
  updateMaterial: (
    materialId: string,
    body: UpdateMaterialBody,
  ) => Promise<void>;
  deleteMaterial: () => Promise<void>;
  collections: MaterialCollection[];
  tags: MaterialTag[];
};

export const MaterialCard: FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState<ModelFile>();

  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [filesClient] = useClientsStore((state) => [state.filesClient]);

  useEffect(() => {
    if (!props.material.thumbnailFileId) {
      setIsLoading(false);
      return;
    }

    const getThumbnail = async () =>
      filesClient.getFile({
        fileId: props.material.thumbnailFileId!,
        returnRelatedPresignedURL: true,
      });

    getThumbnail()
      .then((response) => setThumbnail(response.data))
      .then(() => setIsLoading(false));
  }, [setThumbnail, filesClient, props.material.thumbnailFileId]);

  const deleteMaterial = async () => {
    try {
      setIsDeleting(true);
      await props.deleteMaterial();
    } finally {
      setIsDeleting(false);
    }
  };

  const updateMaterial = async (body: UpdateMaterialBody) => {
    try {
      setIsUpdating(true);
      await props.updateMaterial(props.material.id, body);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Card
      hoverable={false}
      actions={[
        <Popconfirm
          key="delete"
          title="Are you sure to delete this material?"
          onConfirm={deleteMaterial}
          okText="Yes"
          okButtonProps={{ loading: isDeleting }}
          cancelText="No"
        >
          <DeleteOutlined key="delete" />
        </Popconfirm>,
      ]}
    >
      <Skeleton loading={isLoading} avatar active>
        <Space
          style={{ width: "100%", height: 430, overflowY: "scroll" }}
          direction="vertical"
          size="large"
        >
          <Image src={thumbnail?.url ?? ""} />
          <Card.Meta
            title={
              <Tooltip title={props.material.name}>
                <span>{props.material.name}</span>
              </Tooltip>
            }
          />
          <Select
            mode="tags"
            placeholder="Select tags"
            loading={isUpdating}
            style={{ width: "100%" }}
            showArrow
            maxTagCount={1}
            value={(props.material.tags || []).map((tag) => tag.name)}
            onSelect={(key) =>
              updateMaterial({
                tags: [
                  ...(props.material.tags || [])
                    .filter(({ name }) => name !== key)
                    .map((tag) => tag.name),
                  key,
                ],
              })
            }
            onDeselect={(key) =>
              updateMaterial({
                tags: (props.material.tags || [])
                  .filter(({ name }) => name !== key)
                  .map((tag) => tag.name),
              })
            }
          >
            {props.tags.map((tag) => (
              <Select.Option key={tag.name} value={tag.name}>
                {tag.name}
              </Select.Option>
            ))}
          </Select>
          <Select
            mode="multiple"
            placeholder="Select collections"
            loading={isUpdating}
            style={{ width: "100%" }}
            showArrow
            maxTagCount={1}
            value={(props.material.collections || []).map(
              (collection) => collection.path,
            )}
            onSelect={(key) =>
              updateMaterial({
                collections: [
                  ...(props.material.collections || []).filter(
                    ({ path }) => path !== key,
                  ),
                  props.collections.find(({ path }) => path === key)!,
                ],
              })
            }
            onDeselect={(key) =>
              updateMaterial({
                collections: (props.material.collections || []).filter(
                  ({ path }) => path !== key,
                ),
              })
            }
          >
            {props.collections.map((collection) => (
              <Select.Option key={collection.path} value={collection.path}>
                {collection.name}
              </Select.Option>
            ))}
          </Select>
        </Space>
      </Skeleton>
    </Card>
  );
};
