import { useEffect, useState } from "react";

import {
  BrowserRouter as ReactRouter,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { LogoutOutlined } from "@ant-design/icons";
import { Image, Layout, Spin } from "antd";
import jwt_decode from "jwt-decode";

import { SystemAdministratorPermission } from "@omi-lab/ceos-typescript";
import { redirectToLogin } from "@omi-lab/frontend-core";

import FormatPresets from "src/pkgs/FormatPresets";
import ScheduleEvents from "src/pkgs/ScheduleEvents";

import { CameraCollections } from "../pkgs/CameraCollections";
import { Cameras } from "../pkgs/Cameras";
import { Coupons } from "../pkgs/Coupons";
import { LightSetCollections } from "../pkgs/LightSetCollections";
import { LightSets } from "../pkgs/LightSets";
import { MaterialCollections } from "../pkgs/MaterialCollections";
import { Materials } from "../pkgs/Materials";
import { ObjectCollections } from "../pkgs/ObjectCollections";
import { Objects } from "../pkgs/Objects";
import { Organizations } from "../pkgs/Organizations";
import { OrganizationsRender } from "../pkgs/OrganizationsRender";
import { OrganizationVerticals } from "../pkgs/OrganizationVerticals";
import { ProductCreate } from "../pkgs/ProductCreate";
import { Products } from "../pkgs/Products";
import { ProjectTemplates } from "../pkgs/ProjectTemplates";
import { Quotes } from "../pkgs/Quotes";
import { RatioPresets } from "../pkgs/RatioPresets";
import { SkyBoxCollections } from "../pkgs/SkyBoxCollections";
import { SkyBoxes } from "../pkgs/SkyBoxes";
import { SystemAdministrators } from "../pkgs/SystemAdministrators";
import { TemplateCollections } from "../pkgs/TemplateCollections";
import { TemplateCustomPreviews } from "../pkgs/TemplateCustomPreviews";
import { Templates } from "../pkgs/Templates";
import { TemplateTags } from "../pkgs/Templates/components/TaggingList";
import { Translations } from "../pkgs/Translations";
import { useClientsStore } from "../store/clients";

import Menu from "./menu";

interface TokenPayload {
  accountId: string;
  email: string;
  avatarFileId?: string;
  firstName: string;
  lastName: string;
}

function Router() {
  const {
    auth,
    systemAdministratorsClient,
    setToken,
    token,
    setPermissions,
    permissions,
  } = useClientsStore((state) => state);
  const [user, setUser] = useState<TokenPayload>();

  useEffect(() => {
    const refreshToken = async () => {
      try {
        const res = await auth.refreshToken();
        const decoded_token: TokenPayload = await jwt_decode(res.data.token);
        setToken(res.data.token);
        setUser(decoded_token);
      } catch {
        window.location.assign(
          `${process.env.REACT_APP_AUTH_APP_URL}?redirectToUrl=${window.location.href}`,
        );
      }
    };

    refreshToken();
  }, [auth, setToken]);

  useEffect(() => {
    const getSystemAdministrator = async () => {
      try {
        if (!user) {
          return;
        }

        const administrator = await systemAdministratorsClient
          .listSystemAdministrators()
          .then((response) => response.data)
          .then((administrators) =>
            administrators.find(
              (administrator) => administrator.accountId === user.accountId,
            ),
          );

        if (administrator) {
          setPermissions(administrator.permissions);
        }
      } catch {
        const currentLocation = window.location.href.replace(
          "127.0.0.1",
          "localhost",
        );

        redirectToLogin(
          process.env.REACT_APP_AUTH_APP_URL,
          "LogoutForInactivity",
          currentLocation,
        );
      }
    };

    getSystemAdministrator();
  }, [user, setPermissions, systemAdministratorsClient]);

  return token && permissions ? (
    <ReactRouter>
      <Layout style={{ height: "100vh" }}>
        <Layout style={{ height: "70px" }}>
          <Layout.Header
            style={{
              height: "100%",
              justifyContent: "space-between",
              display: "flex",
              backgroundColor: process.env.REACT_APP_HEADER_BACKGROUND_COLOR,
            }}
          >
            <Image
              src="/color_inverted.png"
              alt="Omi"
              width="auto"
              height="75%"
            />
            <span style={{ color: "white" }}>
              Hey <strong>{user?.firstName}</strong>, you are logged in Hades@
              {process.env.REACT_APP_RELEASE_TAG} as{" "}
              <strong>{user?.email}</strong>
              <LogoutOutlined
                style={{ marginLeft: "5px" }}
                onClick={() =>
                  window.location.assign(
                    new URL(
                      "/login",
                      process.env.REACT_APP_AUTH_APP_URL,
                    ).toString(),
                  )
                }
              />
            </span>
          </Layout.Header>
        </Layout>
        <Layout style={{ height: "calc(100% - 70px)" }}>
          <Layout.Sider>
            <Menu />
          </Layout.Sider>
          <Layout.Content style={{ overflow: "scroll" }}>
            {permissions.includes(
              SystemAdministratorPermission.ManageSystemResources,
            ) && (
              <Switch>
                <Route exact path="/">
                  <Redirect to="/objects" />
                </Route>
                <Route exact path="/objects" component={Objects} />
                <Route
                  exact
                  path="/objects/collections"
                  component={ObjectCollections}
                />
                <Route exact path="/templates" component={Templates} />
                <Route
                  exact
                  path="/templates/collections"
                  component={TemplateCollections}
                />
                <Route exact path="/templates/tags" component={TemplateTags} />
                <Route exact path="/cameras" component={Cameras} />
                <Route
                  exact
                  path="/templates/custom-previews"
                  component={TemplateCustomPreviews}
                />
                <Route exact path="/cameras" component={Cameras} />
                <Route
                  exact
                  path="/cameras/collections"
                  component={CameraCollections}
                />
                <Route exact path="/light-sets" component={LightSets} />
                <Route
                  exact
                  path="/light-sets/collections"
                  component={LightSetCollections}
                />
                <Route exact path="/materials" component={Materials} />
                <Route
                  exact
                  path="/materials/collections"
                  component={MaterialCollections}
                />
                <Route exact path="/sky-boxes" component={SkyBoxes} />
                <Route
                  exact
                  path="/sky-boxes/collections"
                  component={SkyBoxCollections}
                />
                <Route
                  exact
                  path="/projects/templates"
                  component={ProjectTemplates}
                />
                <Route exact path="/translations" component={Translations} />
                <Route
                  exact
                  path="/schedule-events"
                  component={ScheduleEvents}
                />
                <Route exact path="/format-presets" component={FormatPresets} />
                <Route exact path="/ratio-presets" component={RatioPresets} />
              </Switch>
            )}
            {permissions.includes(
              SystemAdministratorPermission.ManageOrganizations,
            ) && (
              <Switch>
                <Route exact path="/organizations" component={Organizations} />
                <Route exact path="/renders" component={OrganizationsRender} />
                <Route
                  exact
                  path="/product-verticals"
                  component={OrganizationVerticals}
                />
              </Switch>
            )}
            {permissions.includes(
              SystemAdministratorPermission.ManagePlans,
            ) && (
              <Switch>
                <Route exact path="/billing/products" component={Products} />
                <Route
                  exact
                  path="/billing/products/create"
                  component={ProductCreate}
                />
                <Route exact path="/billing/coupons" component={Coupons} />
                <Route exact path="/billing/quotes" component={Quotes} />
              </Switch>
            )}
            {permissions.includes(
              SystemAdministratorPermission.ManagePermissions,
            ) && (
              <Switch>
                <Route
                  exact
                  path="/system-administrators"
                  component={SystemAdministrators}
                />
              </Switch>
            )}
          </Layout.Content>
        </Layout>
      </Layout>
    </ReactRouter>
  ) : (
    <Spin size="large" />
  );
}

export default Router;
