import type { FC } from "react";
import { useEffect, useState } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Avatar } from "antd";

import type { ModelFile } from "@omi-lab/fedex-typescript";

import { useClientsStore } from "../../../store/clients";

interface Props {
  fileId: string;
}

export const OrganizationLogo: FC<Props> = (props) => {
  const [logo, setLogo] = useState<ModelFile | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const filesClient = useClientsStore((store) => store.filesClient);

  useEffect(() => {
    if (!props.fileId) {
      setIsLoading(false);
      return;
    }
    const getLogo = async () =>
      filesClient
        .getFile({ fileId: props.fileId, returnRelatedPresignedURL: true })
        .then((response) => setLogo(response.data))
        .then(() => setIsLoading(false));

    getLogo();
  }, [props.fileId, filesClient]);

  return isLoading ? <LoadingOutlined /> : <Avatar src={logo?.url ?? ""} />;
};
